<template>
  <main
    :class="[
      $style.main,
      insideApp && $style.insideApp,
      isDota && $style.isDota
    ]"
  >
    <header :class="$style.header" v-if="!hideHeader">
      <img :class="$style.logo" src="./images/logo.svg?url" alt="PARI Pass" />
      <ui-link :class="$style.btn" href="https://www.pari.ru/">
        <span>{{ t('success') }}</span>
      </ui-link>
    </header>
    <section :class="$style.content">
      <div :class="$style.info">
        <h1 :class="$style.title">
          <span v-for="letter in t('soon')" :key="letter">
            {{ letter }}
          </span>
        </h1>
        <p :class="$style.date" v-if="$slots.default">
          <span>
            <slot />
          </span>
        </p>
      </div>
      <img
        :class="$style.depression"
        src="./images/depression.png"
        alt="PARI Pass"
        v-if="!isDota"
      />
      <img
        v-else
        :class="$style.ogre"
        src="./images/ogremag.png"
        alt="PARI Pass"
      />
    </section>
  </main>
</template>

<i18n>
{
  "en": {
    "success": "Go to PARI",
    "date1": "New season",
    "date2": "on August 15",
    "soon": "Soon"
  },
  "ru": {
    "success": "Перейти на PARI",
    "date1": "Новый сезон",
    "date2": "15 августа",
    "soon": "Скоро"
  }
}
</i18n>

<script setup>
import UiLink from '@/components/ui/link/index.vue'

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  hideHeader: {
    type: Boolean,
    default: false
  },
  insideApp: {
    type: Boolean,
    default: false
  },
  isDota: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
</script>

<style lang="scss" module>
.main {
  background-image: url(./images/bg.jpg);
  background-size: cover;
  background-position: center;
  background-color: #050c18;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 0.9em;

  &.insideApp {
    min-height: calc(100vh - var(--app-header-height));
    min-height: calc(100dvh - var(--app-header-height));
  }

  &.isDota {
    .content {
      z-index: 2;

      .info {
        @include up(md) {
          padding-top: 22em;
        }

        .title {
          > span {
            &:nth-child(2) {
              position: relative;
              z-index: -1;
            }
          }
        }

        .date {
          top: 100%;
        }
      }
    }
  }

  @include down(sm) {
    background-image: url(./images/bg-mob.jpg);
  }

  .header {
    padding: 2.2em 10em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include down(sm) {
      padding: 1.6em;
    }

    .logo {
      display: block;
      height: 5em;
      width: auto;

      @include down(sm) {
        height: 3em;
      }
    }

    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      transition: all 0.2s;
      user-select: none;
      transform: skew(-15deg);
      background: #00c7b1;
      border-radius: 0.6em;
      color: #000;
      height: 4.85em;
      padding: 0 3.8em;

      @include down(sm) {
        height: 3.6em;
        padding: 0 1.8em;
        border-radius: 0.4em;
      }

      &:hover {
        background: lighten(#00c7b1, 5%);
      }

      span {
        transform: skew(15deg);
        font-family: Inter;
        font-size: 1.6em;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;

        @include down(sm) {
          font-size: 1.2em;
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20em;
    flex: 1;
    position: relative;
    min-height: 70em;

    @include down(md) {
      //font-size: 0.65em;
    }

    @include down(sm) {
      font-size: 1em;
      align-items: flex-start;
      padding-bottom: 10em;
    }

    .info {
      position: relative;

      .title {
        @include down(sm) {
          padding-top: 6.4em;
        }
        span {
          font-family: var(--primary-font);
          font-size: 35em;
          font-style: italic;
          font-weight: 900;
          line-height: 1;
          letter-spacing: -0.005em;
          text-transform: uppercase;

          @include down(md) {
            font-size: 17.8em;
          }

          @include down(sm) {
            font-size: 9em;
          }
        }
      }

      .date {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + 14em);
        text-align: center;
        white-space: nowrap;

        @include down(sm) {
          top: 52em;
        }

        > span {
          color: #00c7b1;
          font-family: var(--primary-font);
          font-size: 4em;
          font-style: normal;
          font-weight: 700;
          line-height: 1.2;
          text-transform: uppercase;

          @include down(md) {
            font-size: 3.2em;
          }

          br {
            display: none;

            @include down(sm) {
              display: inline;
            }
          }
        }
      }
    }

    .depression {
      position: absolute;
      top: 0;
      width: 55.3em;
      left: 25em;
      right: 0;
      bottom: 16.5em;
      margin: auto;

      @include down(md) {
        width: 29.2em;
        left: 16em;
        bottom: 8.5em;
      }

      @include down(sm) {
        top: 12.4em;
        bottom: auto;
        left: 0;
        right: 0;
        width: 34.4em;
        transform: translateX(1.6em);
      }
    }

    .ogre {
      position: absolute;
      top: 0;
      width: 56.9em;
      left: 14em;
      right: 0;
      bottom: 16.5em;
      margin: auto;
      z-index: -1;
      filter: drop-shadow(-0.5em 0 2em #00020e);

      @include down(md) {
        left: 8em;
        bottom: 30em;
        width: 37.9em;
      }

      @include down(sm) {
        top: 16.4em;
        bottom: auto;
        left: 0;
        right: 0;
        width: 34.4em;
        transform: translateX(1.6em);
      }
    }
  }
}
</style>
